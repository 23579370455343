<template>
  <v-menu
      ref="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="time"
          dense
          :label="label"
          :placeholder="placeholder"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          clearable
          outlined
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
        v-model="time"
        format="24hr"
        :color="color"
        full-width
        @click:minute="$refs.menu.save()"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "wsTimePicker",
  props : ['value','label','placeholder','color'],
  data() {
    return {
      time : ''
    }
  },
  watch : {
    time(value) {
      this.$emit('input',value)
    },
    value(value) {
      this.time = value
    }
  },
  mounted() {
    if  (this.value ) {
      this.time = this.value
    }
  }
}
</script>

<style scoped>

</style>